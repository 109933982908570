var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" In the phase diagram for water, indicate the direction that the solid-liquid and the liquid-gas coexistence lines will move along the temperature axis after the addition of a solute. ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" Solid-liquid coexistence line: "),_c('v-select',{staticClass:"ml-2",staticStyle:{"display":"inline-block","max-width":"300px"},attrs:{"items":_vm.items1,"item-text":"text","item-value":"value","label":"A"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" Liquid-gas coexistence line: "),_c('v-select',{staticClass:"ml-2",staticStyle:{"display":"inline-block","max-width":"300px"},attrs:{"items":_vm.items1,"item-text":"text","item-value":"value","label":"B"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1),_c('p',{staticClass:"mb-4"},[_c('v-img',{staticStyle:{"max-width":"500px"},attrs:{"src":"/img/assignments/solidLiquidGasGraph.png"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" What effect do impurities (or solutes) have on the melting point of a substance? ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" Impurities: "),_c('v-select',{staticClass:"ml-2",staticStyle:{"display":"inline-block","max-width":"300px"},attrs:{"items":_vm.items2Shuffled,"item-text":"text","item-value":"value","label":"C"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" This colligative property is called: "),_c('v-select',{staticClass:"ml-2",staticStyle:{"display":"inline-block","max-width":"300px"},attrs:{"items":_vm.items3Shuffled,"item-text":"text","item-value":"value","label":"D"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }